<template>
    <div class="centered-message">
        <h1 class="text-primary">{{ t('pageNotFound') }}</h1>
        <h2 class="text-primary dashboard-link">
            <b-link to="/">{{ t('returnToDashboard') }}</b-link>
        </h2>
    </div>
</template>
<script>
export default {
    name: 'NotFound',
};
</script>
